import { pipe } from 'ramda';

export function addRootRouteToSubroutes<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [routeName: string]: string | ((...args: any[]) => string) },
>(rootRoute: string, subroutes: T) {
  return Object.entries(subroutes).reduce(
    (fullSubroutes, [subrouteName, subrouteValue]) => ({
      ...fullSubroutes,
      [subrouteName]:
        typeof subrouteValue === 'string'
          ? `${rootRoute}${subrouteValue}`
          : pipe(subrouteValue, (subroute) => `${rootRoute}${subroute}`),
    }),
    {} as T,
  );
}
