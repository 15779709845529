import * as Types from '../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MsalConfigurationVariables = Types.Exact<{
  input: Types.AuthenticationInput;
}>;


export type MsalConfiguration = { __typename?: 'Query', msalConfiguration: { __typename?: 'MsalConfigurationModel', userJourney: string, clientId: string, instance: string, domain: string } };


export const MsalConfigurationDocument = gql`
    query MsalConfiguration($input: AuthenticationInput!) {
  msalConfiguration(input: $input) {
    userJourney
    clientId
    instance
    domain
  }
}
    `;

export function useMsalConfiguration(options: Omit<Urql.UseQueryArgs<MsalConfigurationVariables>, 'query'>) {
  return Urql.useQuery<MsalConfiguration, MsalConfigurationVariables>({ query: MsalConfigurationDocument, ...options });
};