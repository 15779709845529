import * as Types from '../../../serverCache/schema.types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CurrentUserDataVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserData = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUserModel', id: string, name?: string | null, email: string, roles: Array<Types.UserRoleEnum>, selectedTenantId: string, tenantId: string, agreements: { __typename?: 'UserAgreementsModel', eula?: { __typename?: 'UserAgreementModel', id: string, accepted: boolean, lastModifiedDate?: string | null } | null, ai?: { __typename?: 'UserAgreementModel', id: string, accepted: boolean, lastModifiedDate?: string | null } | null } }, userProfile: { __typename?: 'UserProfileModel', hasProductAccess: boolean, welcomeMessageShown: boolean }, tenants: Array<{ __typename?: 'TenantModel', id: string, name: string, noMatchesImageUrl: string }>, managedTenants: Array<{ __typename?: 'TenantModel', id: string, name: string, noMatchesImageUrl: string }> };

export type CurrentUserTenant = { __typename?: 'TenantModel', id: string, name: string, noMatchesImageUrl: string };

export type CurrentUserUser = { __typename?: 'CurrentUserModel', id: string, name?: string | null, email: string, roles: Array<Types.UserRoleEnum>, selectedTenantId: string, tenantId: string, agreements: { __typename?: 'UserAgreementsModel', eula?: { __typename?: 'UserAgreementModel', id: string, accepted: boolean, lastModifiedDate?: string | null } | null, ai?: { __typename?: 'UserAgreementModel', id: string, accepted: boolean, lastModifiedDate?: string | null } | null } };

export type CurrentUserAgreement = { __typename?: 'UserAgreementModel', id: string, accepted: boolean, lastModifiedDate?: string | null };

export const CurrentUserTenant = gql`
    fragment CurrentUserTenant on TenantModel {
  id
  name
  noMatchesImageUrl
}
    `;
export const CurrentUserAgreement = gql`
    fragment CurrentUserAgreement on UserAgreementModel {
  id
  accepted
  lastModifiedDate
}
    `;
export const CurrentUserUser = gql`
    fragment CurrentUserUser on CurrentUserModel {
  id
  name
  email
  roles
  selectedTenantId
  tenantId
  agreements {
    eula {
      ...CurrentUserAgreement
    }
    ai {
      ...CurrentUserAgreement
    }
  }
}
    ${CurrentUserAgreement}`;
export const CurrentUserDataDocument = gql`
    query CurrentUserData {
  currentUser {
    ...CurrentUserUser
  }
  userProfile {
    hasProductAccess
    welcomeMessageShown
  }
  tenants {
    ...CurrentUserTenant
  }
  managedTenants {
    ...CurrentUserTenant
  }
}
    ${CurrentUserUser}
${CurrentUserTenant}`;

export function useCurrentUserData(options?: Omit<Urql.UseQueryArgs<CurrentUserDataVariables>, 'query'>) {
  return Urql.useQuery<CurrentUserData, CurrentUserDataVariables>({ query: CurrentUserDataDocument, ...options });
};