import {
  BrowserAuthError,
  Configuration,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { Client, fetchExchange } from 'urql';

import { API_URL } from 'serverCache/constants';
import { ROUTES } from 'pages/shared/routes';

import {
  MsalConfiguration as MsalConfigurationResult,
  MsalConfigurationDocument,
  MsalConfigurationVariables,
} from './auth.generated';
import { saveURLBeforeAutoLogout } from './preserveURLAfterAutoLogout';

type MsalServerConfiguration = Omit<
  MsalConfigurationResult['msalConfiguration'],
  '__typename'
>;

// eslint-disable-next-line no-underscore-dangle
let _msalInstanceSingleton: PublicClientApplication | undefined;

export function getMsalInstance(): PublicClientApplication {
  if (_msalInstanceSingleton) {
    return _msalInstanceSingleton;
  }

  throw new Error(
    `Msal instance: you're not supposed to call msal instance before it is set up.`,
  );
}

async function setupMsalInstance(msalConfiguration: MsalServerConfiguration) {
  _msalInstanceSingleton = new PublicClientApplication(
    getMsalInstanceConfig(msalConfiguration),
  );
  await _msalInstanceSingleton.initialize();

  // https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications#handleredirectpromise
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#using-loginredirect-or-acquiretokenredirect
  // "When using redirect APIs, handleRedirectPromise must be invoked when returning from the redirect."
  // this prevents infinite redirect loops which happen in Firefox on refresh token update (it uses acquireTokenRedirect because updating via hidden iframe fails)
  await _msalInstanceSingleton.handleRedirectPromise();

  return _msalInstanceSingleton;
}

export function getIsAuthenticated() {
  return _msalInstanceSingleton
    ? _msalInstanceSingleton.getAllAccounts().length > 0
    : false;
}

function getScopes(clientId: string) {
  // clientId is needed in scopes to fix issue with duplicated acquire token requests on each acquireTokenSilent().
  // It looks like it's used as some sort of a cache key.
  // https://docs.microsoft.com/en-us/azure/active-directory-b2c/access-tokens#openid-connect-scopes

  return [clientId];
}

type TokenInfo = {
  token: string;
  expiresOn: Date | null;
};

let lastValidTokenInfo: TokenInfo | undefined;

export function setLastValidTokenInfo(info: TokenInfo) {
  lastValidTokenInfo = info;
}

// use separate from other app graphql client just for getting the msal configuration
// because all other queries require auth exchange to be configured, to have auth header in queries
const graphqlClient = new Client({
  url: API_URL,
  exchanges: [fetchExchange],
});

async function loadMsalConfiguration(email: string) {
  const response = await graphqlClient.query<
    MsalConfigurationResult,
    MsalConfigurationVariables
  >(MsalConfigurationDocument, {
    input: {
      email,
    },
  });
  const result = response.data?.msalConfiguration;

  if (result) {
    cacheMsalConfiguration(result);

    return result;
  }

  throw (
    response.error ??
    new Error('Something went wrong while trying to load MSAL configuration.')
  );
}

export type InitAuthenticationResult =
  | { status: 'login-needed' }
  | InitAuthenticationSuccess;
export type InitAuthenticationSuccess = {
  status: 'ok';
  msalInstance: PublicClientApplication;
};

export async function initAuthentication(
  onRedirectToLogin: () => void,
): Promise<InitAuthenticationResult> {
  const email = getLastUsedEmail();
  if (email) {
    const configuration =
      getCachedMsalConfiguration() ?? (await loadMsalConfiguration(email));
    const msal = await setupMsalInstance(configuration);

    return { status: 'ok', msalInstance: msal };
  }

  saveURLBeforeAutoLogout();
  // no email means the app knows nothing about the user, login first
  onRedirectToLogin();

  return { status: 'login-needed' };
}

export async function acquireAccessToken() {
  const msalInstance = getMsalInstance();

  // read this to understand the try/catch logic
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal
  try {
    return await _acquireAccessToken();
  } catch (error: unknown) {
    saveURLBeforeAutoLogout();

    // As stated in the docs (link is in the comment above),
    // if refresh token is expired (which happens each 24h), MSAL tries first to update it
    // with hidden iframe. This may (and most likely will) fail and we'll end up here, in catch.
    // In some circumstances/browsers MSAL throws InteractionRequiredAuthError (like in Safari)
    // in some we experience BrowserAuthError with monitor_window_timeout code.
    // When we catch either of those, we do a redirect to update tokens.
    // There's a separate doc on how to handle monitor_window_timeout:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#monitor_window_timeout
    // fixes from this were tried but failed. Pointing redirectUri to the blank page didn't help.
    // Also, a couple of times (but not always!) the "Refuse to display 'https://login.microsoftonline.com' in a frame because it set 'X-Frame-Options' to 'deny'"
    // error was thrown. Further examining of b2c BE request's response headers show, that the header is in our b2c request.
    // Which means that iframe flow will always fail in our scenario because of b2c headers (which we cannot change).
    // Some links about this:
    // https://stackoverflow.com/a/69649139
    // https://nafis.dev/2021/03/12/aad-iframe/
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/iframe-usage.md
    // In general, by far the most popular suggestion on how to handle it is to catch the error
    // and do a redirect (this is what implemented here):
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5222#issuecomment-1251415615
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1161#issuecomment-566299863
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1266#issuecomment-582602349
    if (
      error instanceof InteractionRequiredAuthError ||
      (error instanceof BrowserAuthError &&
        error.errorCode === 'monitor_window_timeout')
    ) {
      const msalConfiguration = msalInstance.getConfiguration().auth;
      // refresh token is expired, get new pair of tokens
      await msalInstance.acquireTokenRedirect({
        scopes: getScopes(msalConfiguration.clientId),
      });
    } else {
      window.console.error({ error });
      clearCurrentUserData();
      await msalInstance.logoutRedirect();
    }

    // this is never reached because every cond. branch above has a redirect in the end of it
    throw new Error('Something went wrong while acquiring access token.');
  }
}

// eslint-disable-next-line no-underscore-dangle
async function _acquireAccessToken(): Promise<string> {
  const msalInstance = getMsalInstance();
  const email = getLastUsedEmail();

  if (msalInstance && email) {
    const shouldRequestToken = (tokenInfo: TokenInfo) => {
      const { expiresOn } = tokenInfo;
      return !expiresOn
        ? true
        : // view token as old when it's 2 minutes from getting expired
          expiresOn.getTime() <= new Date().getTime() + 60 * 2 * 1000;
    };

    // call to the acquireTokenSilent makes msal instance publish events the MsalProvider listens to. When these events are published, MsalInstance updates its react state. For some reason this causes apollo useQuery hooks to be re-rendered, which result in many rerenders in the app we don't actually need.
    // this optimizes, for example, general IS performance and the speed of going from
    // IS to the Viewer via image clicking
    if (
      lastValidTokenInfo !== undefined &&
      !shouldRequestToken(lastValidTokenInfo)
    ) {
      return lastValidTokenInfo.token;
    }

    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const allAccounts = msalInstance.getAllAccounts();

    if (!activeAccount && allAccounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */

      throw new Error(
        'Could not find an active account when acquiring access token.',
      );
    }

    const msalConfiguration = msalInstance.getConfiguration().auth;

    const request: SilentRequest = {
      scopes: getScopes(msalConfiguration.clientId),
      account: activeAccount || allAccounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    const { idToken: token, expiresOn } = authResult;
    setLastValidTokenInfo({ token, expiresOn });

    return token;
  }

  throw new Error('Cannot acquire tokens without msal instance and email.');
}

export const login = async (email: string) => {
  const msalServerConfiguration = await loadMsalConfiguration(email);
  const msal = await setupMsalInstance(msalServerConfiguration);

  setLastUsedEmail(email);

  msal
    .loginRedirect({
      scopes: getScopes(msalServerConfiguration.clientId),
      authority: makeAuthorityUrl(msalServerConfiguration),
    })
    .catch(window.console.error);
};

export async function logout() {
  clearCurrentUserData();
  getMsalInstance()
    // redirect to login after the manual logout is done
    // to avoid getting redirected to the page from which user logged out
    // once user is logged in
    .logoutRedirect({ postLogoutRedirectUri: ROUTES.login })
    .catch(window.console.error);
}

export async function getAuthorizationHeader() {
  const token = await acquireAccessToken();

  return {
    authorization: token !== '' ? `Bearer ${token}` : '',
  };
}

function getMsalInstanceConfig(
  msalConfiguration: MsalServerConfiguration,
): Configuration {
  return {
    auth: {
      clientId: msalConfiguration.clientId, // This is the ONLY mandatory field that you need to supply.
      authority: makeAuthorityUrl(msalConfiguration), // Use a sign-up/sign-in user-flow as a default authority
      knownAuthorities: [msalConfiguration.instance], // Mark your B2C tenant's domain as trusted.
      redirectUri: `${window.location.origin}${ROUTES.login}`, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      // iframe in acquireTokenSilent fails all the time, so just kill it immediately
      iframeHashTimeout: 1,
      loggerOptions: {
        logLevel: LogLevel.Error,
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              window.console.error(message);
              return;
            case LogLevel.Info:
              window.console.info(message);
              return;
            case LogLevel.Verbose:
              window.console.debug(message);
              return;
            case LogLevel.Warning:
              window.console.warn(message);
              break;
            default:
          }
        },
      },
    },
  };
}

function makeAuthorityUrl(msalConfiguration: MsalServerConfiguration) {
  return `${msalConfiguration.instance}/${msalConfiguration.domain}/${msalConfiguration.userJourney}`;
}

const MSAL_CONFIGURATION_KEY = 'msal_config';

// msal config is cached to avoid loading it each time user opens the app
// which adds to the app loading time for the user
// (we can't render any content while the config is being loaded)
// if the config is changed at any point in time (which should almost never happen)
// user will have to just login from the scratch and load the config from the server
function cacheMsalConfiguration(msalConfiguration: MsalServerConfiguration) {
  localStorage.setItem(
    MSAL_CONFIGURATION_KEY,
    JSON.stringify(msalConfiguration),
  );
}

function getCachedMsalConfiguration() {
  const lsValue = localStorage.getItem(MSAL_CONFIGURATION_KEY);
  return lsValue !== null
    ? (JSON.parse(lsValue) as MsalServerConfiguration)
    : lsValue;
}

const LAST_USED_EMAIL_KEY = 'last_used_email';

function setLastUsedEmail(email: string | null) {
  if (email) {
    localStorage.setItem(LAST_USED_EMAIL_KEY, email);
  } else {
    localStorage.removeItem(LAST_USED_EMAIL_KEY);
  }
}

function clearCurrentUserData() {
  setLastUsedEmail(null);
  localStorage.removeItem(MSAL_CONFIGURATION_KEY);
}

function getLastUsedEmail() {
  return localStorage.getItem(LAST_USED_EMAIL_KEY);
}
