import { join, mapObjIndexed, pipe, remove, split } from 'ramda';

import { ROUTES } from './baseRoutes';
import { addRootRouteToSubroutes } from './addRootRouteToSubroutes';

const ADMIN_SUBROUTES = {
  indgUsers: 'indg-users',
  tenantUsers: 'tenant-users',
  userProvisioningLists: 'user-provisioning-lists',
  financeReport: 'finance-report',
  budgets: 'budgets',
  transactionsReport: 'transactions-report',
  productGroups: 'product-groups',
  brands: 'brands',
  genres: 'genres',
  projects: 'projects',
  shapes: 'shapes',
  shapeCollections: 'shape-collections',
  products: 'products',
  artworkSets: 'artwork-sets',
  contentKits: 'content-kits',
  templates: 'templates',
  tenants: 'tenants',
  groups: 'groups',
  roles: 'roles',
  additionalImageTypes: 'additional-image-types',
  scenes: 'scenes',
  draftProducts: 'draft-products',
  filterSettings: 'settings/filter',
  eulaAgreement: 'agreements/eula',
  eulaAgreementAcceptance: 'agreements/eula/user-acceptance',
  aiAgreement: 'agreements/ai',
  aiAgreementAcceptance: 'agreements/ai/user-acceptance',
};

const ADMIN_BASE_ROUTES = addRootRouteToSubroutes(
  `${ROUTES.admin}/`,
  ADMIN_SUBROUTES,
);

export type EntityIdUrlParamKey =
  | 'productId'
  | 'draftProductId'
  | 'userId'
  | 'tenantId'
  | 'listId'
  | 'budgetId'
  | 'brandId'
  | 'genreId'
  | 'projectId'
  | 'artworkSetId'
  | 'contentKitId'
  | 'templateId'
  | 'productGroupId'
  | 'shapeCollectionId'
  | 'additionalImageTypeId'
  | 'sceneId';

export const NEW_ENTITY_PARAM_NAME = 'new';

const makeViewEntityRoute =
  (entityIdKey: EntityIdUrlParamKey, baseRoute: string) =>
  (entityId?: number | string) =>
    entityId ? `${baseRoute}/${entityId}` : `${baseRoute}/:${entityIdKey}`;
export const makeBaseRouteFromViewEntityRoute = (viewEntityRoute: string) =>
  pipe(split('/'), remove(-1, 1), join('/'))(viewEntityRoute);

export const ADMIN_VIEW_ENTITY_ROUTES = {
  indgUser: makeViewEntityRoute('userId', ADMIN_BASE_ROUTES.indgUsers),
  tenantUser: makeViewEntityRoute('userId', ADMIN_BASE_ROUTES.tenantUsers),
  userProvisioningList: makeViewEntityRoute(
    'listId',
    ADMIN_BASE_ROUTES.userProvisioningLists,
  ),
  productGroup: makeViewEntityRoute(
    'productGroupId',
    ADMIN_BASE_ROUTES.productGroups,
  ),
  budget: makeViewEntityRoute('budgetId', ADMIN_BASE_ROUTES.budgets),
  brand: makeViewEntityRoute('brandId', ADMIN_BASE_ROUTES.brands),
  genre: makeViewEntityRoute('genreId', ADMIN_BASE_ROUTES.genres),
  project: makeViewEntityRoute('projectId', ADMIN_BASE_ROUTES.projects),
  shapeCollection: makeViewEntityRoute(
    'shapeCollectionId',
    ADMIN_BASE_ROUTES.shapeCollections,
  ),
  product: makeViewEntityRoute('productId', ADMIN_BASE_ROUTES.products),
  draftProduct: makeViewEntityRoute(
    'draftProductId',
    ADMIN_BASE_ROUTES.draftProducts,
  ),
  artworkSet: makeViewEntityRoute(
    'artworkSetId',
    ADMIN_BASE_ROUTES.artworkSets,
  ),
  contentKit: makeViewEntityRoute(
    'contentKitId',
    ADMIN_BASE_ROUTES.contentKits,
  ),
  template: makeViewEntityRoute('templateId', ADMIN_BASE_ROUTES.templates),
  tenant: makeViewEntityRoute('tenantId', ADMIN_BASE_ROUTES.tenants),
  imageType: makeViewEntityRoute(
    'additionalImageTypeId',
    ADMIN_BASE_ROUTES.additionalImageTypes,
  ),
  scene: makeViewEntityRoute('sceneId', ADMIN_BASE_ROUTES.scenes),
};

// relative ADMIN_ROUTES_PATHS with * wildcard at the end is used for <Route /> to make
// Route matching work, because react-router needs relative urls given our react tree structure;
// absolute paths (ADMIN_ROUTES) are used everywhere else (for navigation, links, etc.)
export const ADMIN_ROUTES_PATHS = mapObjIndexed(
  (x) => `${x}/*`,
  ADMIN_SUBROUTES,
);
export const ADMIN_ROUTES = {
  ...ADMIN_BASE_ROUTES,
  ...ADMIN_VIEW_ENTITY_ROUTES,
};
