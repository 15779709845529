import { mapObjIndexed } from 'ramda';

import { addRootRouteToSubroutes } from './addRootRouteToSubroutes';
import { ROUTES } from './baseRoutes';

const USER_PROFILE_SUBROUTES = {
  agreements: 'agreements',
};

export const USER_PROFILE_ROUTES = addRootRouteToSubroutes(
  `${ROUTES.userProfile}/`,
  USER_PROFILE_SUBROUTES,
);

export const USER_PROFILE_ROUTES_PATHS = mapObjIndexed(
  (x) => `${x}/*`,
  USER_PROFILE_SUBROUTES,
);
