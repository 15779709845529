import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { Client } from 'urql';

import {
  CurrentUserData,
  CurrentUserDataDocument,
} from 'shared/hooks/useCurrentUserData';
import { extractUserName, getMsalInstance } from 'features/auth';

const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_APP_INSIGHTS_CONNECTION_STRING, // will to be inserted by .gitlab-ci.yml. For local development find the connection string on Azure portal and add it to corresponding envs/.env file
    extensions: [reactPlugin],
    // check out if anythings changes for latest React router or React 19
    // https://github.com/microsoft/applicationinsights-react-js/issues/137
    enableAutoRouteTracking: true,
  },
});

export async function setupCustomTelemetry(graphQLClient: Client) {
  const telemetryData = await getDataForAnalytics(graphQLClient);

  appInsights.addTelemetryInitializer((envelope) => {
    /* eslint-disable no-param-reassign */
    if (envelope.data === undefined) {
      envelope.data = {};
    }

    if (telemetryData.tenant) {
      envelope.data.tenantId = telemetryData.tenant.id;
      envelope.data.tenantName = telemetryData.tenant.name;
    }

    const userName = extractUserName(getMsalInstance().getAllAccounts());

    if (
      userName !== undefined &&
      telemetryData.userEmail &&
      telemetryData.tenantUserBelongsTo
    ) {
      envelope.data.userName = userName;
      envelope.data.userEmail = telemetryData.userEmail;
      envelope.data.userTenantId = telemetryData.tenantUserBelongsTo.id;
      envelope.data.userTenantName = telemetryData.tenantUserBelongsTo.name;
    }
    /* eslint-enable no-param-reassign */
  });

  try {
    appInsights.loadAppInsights();
  } catch (e: unknown) {
    if (
      e instanceof Error &&
      e.message === 'Please provide instrumentation key'
    ) {
      window.console.warn(
        `Instrumentation key hasn't been set (OK when running locally)`,
        e,
      );
    }
  }
}

export async function getDataForAnalytics(graphQLClient: Client) {
  const data = await graphQLClient.query<CurrentUserData>(
    CurrentUserDataDocument,
    {},
  );

  const userEmail = data?.data?.currentUser?.email;
  const userRoles = data.data?.currentUser.roles;
  const selectedTenantId: string | undefined =
    data?.data?.currentUser?.selectedTenantId;
  const userTenantId = data?.data?.currentUser?.tenantId;
  const tenants: { id: string; name: string }[] = (
    data?.data?.tenants ?? []
  ).concat(data?.data?.managedTenants ?? []);

  return {
    tenant: tenants.find((x) => x.id === selectedTenantId),
    tenantUserBelongsTo: tenants.find((x) => x.id === userTenantId),
    userEmail,
    userRoles,
  };
}
